.CountdownTimer {
  text-align: center; }

.CountdownTimer__time-unit {
  display: inline-block;
  margin-left: 10px; }

.CountdownTimer__time-unit:first-child {
  margin-left: 0; }

.CountdownTimer__time-unit__value {
  display: block;
  color: $red;
  font-size: 1.857em;
  font-weight: bold; }

.CountdownTimer__time-unit__name {
  color: $semi-light-grey; }
