.medium-editor-toolbar-form {
  padding: 4px;
  border: 1px solid #333333;
  background: white;

  input.medium-editor-toolbar-input,
  input.medium-editor-toolbar-input:hover,
  input.medium-editor-toolbar-input:active,
  input.medium-editor-toolbar-input:focus {
    padding: 4px 2px;
    border: 1px solid #333333; } }

.medium-editor-anchor-preview {
  padding: 4px;
  color: #333333;
  border: 1px solid #333333;
  background: white;

  a.medium-editor-toolbar-anchor-preview-inner {
    color: #333333; } }
