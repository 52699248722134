.OrderItems {
  background: $super-light-grey;
  border: 1px solid $very-light-grey; }

.OrderItems__item {
  & > td {
    max-width: 295px;

    @media(max-width: $breakpoint-mobile) {
      max-width: 215px; } } }

.OrderItems__item__image {
  width: 50px;
  margin-right: 10px;
  float: left; }

.OrderItems__total {
  font-weight: bold; }
