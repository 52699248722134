.Campaign {
  margin-top: 15px;

  @media(max-width: $breakpoint-mobile) {
    margin-top: 5px; }

  h1 {
    margin: 5px 0;
    font-size: 18px;
    font-weight: bold; } }

.Campaign__banner {
  margin-bottom: 10px;

  @media(max-width: $breakpoint-mobile) {
    margin-bottom: 5px; } }

.Campaign__product-navigation-counter {
  display: block;
  width: 100%;
  text-align: center;

  @media(min-width: $breakpoint-mobile) {
    padding-top: 5px; } }

.Campaign__image-gallery {
  display: flex;

  & > button {
    color: $semi-light-grey;
    border: 0;
    background: none;

    @media(min-width: $breakpoint-mobile) {
      font-size: 2.5rem;

      span {
        top: -5rem; } }

    @media(max-width: $breakpoint-mobile) {
      span {
        top: -3.5rem; } } } }

.Campaign__info {
  @media(min-width: $breakpoint-mobile) {
    padding-right: 25px; } }

.Campaign__info > div.Campaign__info__title {
  display: flex;
  align-items: center; }

.Campaign__subtitle {
  margin-left: 5px;
  color: $black;
  font-size: 14px;
  font-weight: normal; }

.Campaign__language-switcher {
  display: flex;
  margin-left: auto;

  & > button {
    padding: 2px;
    color: $red; } }

.Campaign__current-product-price {
  display: block;
  margin: 5px 0;
  color: $red;
  font-size: 2.571em;
  font-weight: bold;

  @media(max-width: $breakpoint-mobile) {
    font-size: 2em; } }

.Campaign__current-product-price__currency {
  font-size: 0.6em; }

.Campaign__current-product-price__shipping-costs {
  color: $black;
  font-size: 0.4em;
  font-weight: normal;

  @media(max-width: $breakpoint-mobile) {
    display: block; } }

.Campaign__description {
  margin: 10px 0;

  @media(max-width: $breakpoint-mobile) {
    margin: 5px 0 10px 0; } }

.Campaign__products {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0;

  .form-group {
    margin-bottom: 5px; } }

.Campaign__products__select {
  margin-top: 5px; }

.Campaign__product {
  list-style-type: none; }

.Campaign__status {
  margin-top: -5px; }

.Campaign__social-media {
  margin-top: 20px;
  font-size: 24px;

  div {
    padding: 2px; }

  div:first-child {
    padding-left: 15px; }

  div:last-child {
    padding-right: 15px; }

  a {
    width: 100%;
    color: white; }

  .Campaign__social-media__twitter {
    background: #26c4f1; }

  .Campaign__social-media__facebook {
    background: #306199; }

  .Campaign__social-media__email {
    background: #ababab; }

  @media(max-width: $breakpoint-mobile) {
    padding-bottom: 15px; } }

.Campaign__login {
  margin-top: 15px; }

.Campaign__powered-by {
  margin-top: 15px;

  img {
    position: relative;
    top: -2px;
    display: inline-block;
    max-width: 120px;
    filter: grayscale(1.0); } }

.Campaign__finished {
  display: block;
  width: 100%;
  font-size: 18px;
  text-align: center; }
