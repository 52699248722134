.Tools__tool-bar {
  padding-left: 0;
  z-index: $layer-bottom;

  li {
    position: relative;
    list-style-type: none; }

  > li > button {
    color: $grey;
    font-size: 3.5em; } }

.Tools__tool-wrapper {
  position: absolute;
  top: 10px;
  left: 8em;
  width: 307px;
  max-height: 450px;
  border: 1px solid $light-grey;
  background: white;
  z-index: $layer-intermediate; }

.Tools__tool-wrapper:before {
  display: block;
  position: absolute;
  top: 5px;
  left: -28px;
  width: 28px;
  height: 55px;
  background-image: url("../img/editor/editor_control_arrow_left.gif");
  background-repeat: no-repeat;
  content: "";
  z-index: $layer-top; }

.Tools__tool {
  min-height: 350px;
  max-height: 440px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto; }

.DesignElementsTools__categories, .DesignElementsTools__elements {
  button {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0;
    background: $very-light-grey; }

  button:hover {
    background: $very-light-grey; }

  img {
    width: 25px;
    height: 25px; } }

.UploadTools {
  .ImageFileUpload__image-wrapper {
    width: 50%;
    margin-bottom: 25px;

    @media(max-width: $breakpoint-mobile) {
      width: 100%; } } }
