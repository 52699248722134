.AdditionalProductsPicker__products {
  margin: 40px auto;
  padding-left: 0; }

.AdditionalProductsPicker__products__product {
  margin: 10px 0;
  padding: 10px 0;
  border-bottom: 1px solid $light-grey;
  list-style-type: none; }

.AdditionalProductsPicker__products__product:last-child {
  border-bottom: none; }

.AdditionalProductsPicker__products__product__image {
  width: 64px;
  height: 64px;

  img {
    width: 64px;
    height: 64px; } }

.AdditionalProductsPicker__products__product__description {
  padding: 0 10px;

  .ColorPalette {
    margin-top: 10px; } }

.AdditionalProductsPicker__add-remove-button,
.AdditionalProductsPicker__add-remove-button:hover,
.AdditionalProductsPicker__add-remove-button:focus {
  display: block;
  margin: 5px auto;
  padding: 8px 11px;
  border: 1px solid $light-grey;
  background: none; }

.AdditionalProductsPicker__add-remove-button--add {
  color: $light-blue; }
