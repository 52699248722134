.form-control {
  border-radius: 0; }

.form-validation-error {
  padding: 0 5px;
  color: white;
  background: $dark-red; }

input, select, textarea {
  background: white; }

input[type=number] {
  -moz-appearance: textfield; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0 /* Removes leftover margin */; }
