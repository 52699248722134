/*
 |--------------------------------------------------------------------------
 | Breakpoints
 |--------------------------------------------------------------------------
 */
$breakpoint-tiny: 480px;
$breakpoint-mobile: 768px;

/*
 |--------------------------------------------------------------------------
 | Colors
 |--------------------------------------------------------------------------
 */
$black:            #333333;
$dark-red:         #d53d52;
$red:              #e73d51;
$super-light-grey: #fbfbfb;
$very-light-grey:  #eeeeee;
$light-grey:       #d0d0cf;
$semi-light-grey:  #6d6c6c;
$grey:             #4a4a4a;
$light-blue:       #91c7e0;
$blue:             #05a4c9;

/*
 |--------------------------------------------------------------------------
 | Font Sizes
 |--------------------------------------------------------------------------
 */
$base-font-size: 14px;

/*
 |--------------------------------------------------------------------------
 | Footer
 |--------------------------------------------------------------------------
 */
$footer-height: 60px;

/*
 |--------------------------------------------------------------------------
 | Z-Index
 |--------------------------------------------------------------------------
 */
$layer-bottom:           0;
$layer-intermediate:    10;
$layer-top:             20;
$layer-max-top:       9999;
