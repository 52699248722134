.ColorPalette {
  padding-left: 0; }

.ColorPalette__color {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 5px;
  border: none;
  border-radius: 50%;
  box-shadow: 0.4px 0.5px 4px rgba(0, 0, 0, 0.75); }

.ColorPalette__color:last-child {
  margin-right: 0; }

.ColorPalette__color:hover, .ColorPalette__color--active {
  cursor: pointer; }
