.Editor__button--next-step {
  display: table;
  margin: 40px auto;
  font-size: 28px; }

.Editor__campaign__amount {
  display: block;
  margin-bottom: 25px;
  font-size: 2em; }

.Editor__campaign__prices {
  top: -10px;
  font-size: 0.9em;

  li {
    margin-right: 20px; } }

.Editor__campaign__prices__price-name {
  position: relative;
  top: 5px;
  font-size: 0.8em; }

.Editor__campaign__prices__profit {
  font-size: 2.5em; }
