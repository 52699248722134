.OrderForm__payment__payment-errors {
  display: none;
  margin-bottom: 10px;
  padding: 10px;
  color: white;
  background: $red; }

.OrderForm__submit-btn {
  @media(max-width: $breakpoint-mobile) {
    width: 100%;
    font-size: 17px; } }
