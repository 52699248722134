.progress-small {
  height: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 0;
  box-shadow: none;

  .progress-bar {
    box-shadow: none; } }

.progress-bar-red {
  background-color: $red; }
