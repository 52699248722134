.SizeTable.table > thead > tr > th,
.SizeTable.table > thead > tr > td,
.SizeTable.table > tbody > tr > th,
.SizeTable.table > tbody > tr > td,
.SizeTable.table > tfoot > tr > th,
.SizeTable.table > tfoot > tr > td {
  padding: 6px;
  text-align: center; }

.SizeTable__measure-name {
  color: $red; }
