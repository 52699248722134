.Cart__add-current-product-btn-wrapper {
  @media(max-width: $breakpoint-mobile) {
    padding-left: 0;
    text-align: right; } }

.Cart__show-cart-btn {
  margin: 5px 0;
  font-size: 0.95em; }

.Cart__add-current-product-btn {
  margin: 5px 0;
  font-size: 0.95em; }

.Cart__modal {
  .modal-body, .modal-footer {
    background: $super-light-grey; } }

.Cart__items {
  background: white;

  tbody tr td {
    border-top: 0; }

  tbody tr.Cart__items__item td {
    border-top: 1px solid $light-grey; } }

.Cart__item-price {
  vertical-align: middle !important; }

.Cart__add-item-button, .Cart__add-item-button:hover, .Cart__add-item-button:active, .Cart__add-item-button:focus {
  border: 0;
  background: $super-light-grey; }

.Cart__remove-item-button, .Cart__remove-item-button:hover, .Cart__remove-item-button:active, .Cart__remove-item-button:focus {
  border: 0;
  background: white; }

.Cart__custom-order-field__tooltip {
  display: block;
  color: $semi-light-grey; }

@media(max-width: $breakpoint-mobile) {
  .Cart__items--mobile {
    padding: 0 5px;

    li {
      list-style-type: none; }

    .form-control {
      padding: 0;
      text-align: center; } }

  .Cart__item--mobile {
    padding: 5px 0 0 0;
    border-bottom: 1px solid $light-grey;

    li {
      margin-bottom: 2px;
      padding: 0 2px; } }

  .Cart__item-price--mobile {
    display: block;
    padding: 5px;
    font-size: 20px; }

  .Cart__remove-item-button--mobile {
    margin-top: 4px; } }
