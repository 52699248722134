.Order__details-heading {
  display: flex;
  align-items: center; }

.Order__language-switcher {
  display: flex;
  margin-left: auto;
  padding-top: 12px;

  & > button {
    color: $red; } }

.Order__payment-info {
  padding-left: 0;

  @media(max-width: $breakpoint-mobile) {
    li {
      margin-bottom: 5px;
      list-style-type: none; }

    li:first-child {
      border-top: none; } } }
