.rangeslider, .rangeslider__fill {
  border-radius: 0;
  box-shadow: none; }

.rangeslider--horizontal {
  height: 10px; }

.rangeslider__fill {
  background: $light-blue; }

.rangeslider__handle {
  top: -15px !important;
  background: $red;
  box-shadow: none; }

.rangeslider__handle::after {
  background-image: none; }
