$campaign-step-number-width: 70px;
$campaign-step-number-height: $campaign-step-number-width;
$campaign-items-left-margin: ($campaign-step-number-width + 20px);

.Steps__wrapper {
  display: table;
  position: relative;
  margin: 0 auto 50px auto;

  hr {
    position: absolute;
    top: ($campaign-step-number-height / 2 - 1);
    width: 100%;
    height: 2px;
    margin: 0;
    padding: 0;
    background: $light-grey;
    z-index: $layer-bottom; } }

.Steps {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    display: inline-block;
    margin: 0 20px;
    text-align: center;
    vertical-align: middle; }

  li:first-child {
    margin-left: 0; }

  li:last-child {
    margin-right: 0; }

  li:hover {
    cursor: pointer; } }

.Steps__step {
  display: block;
  position: relative;
  width: $campaign-step-number-width;
  height: $campaign-step-number-height;
  margin: 0 auto;
  color: white;
  font-size: 3.5em;
  font-weight: bold;
  text-align: center;
  line-height: $campaign-step-number-height;
  border-radius: 50%;
  background: $light-grey;
  z-index: $layer-intermediate; }

.Steps__step--finished {
  background: $grey; }


.Steps__step--active {
  background: $red; }

.Steps__step--go {
  font-size: 2em; }

