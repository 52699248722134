.Image-Gallery {
  position: relative;
  max-width: 500px; }

.Image-Gallery > img {
  position: relative;
  width: 100%; }

.Image-Gallery__thumbnails__carousel {
  display: flex;
  justify-content: center;
  padding: 10px 0;

  button {
    color: $semi-light-grey;
    border: 0;
    background: none;

    @media(min-width: $breakpoint-mobile) {
      font-size: 2rem; } } }

.Image-Gallery__thumbnail {
  display: block;
  position: relative;
  width: 20%;
  margin-left: 10px;
  opacity: 0.5;
  filter: alpha(opacity=40) /* <= IE8 */;

  img {
    width: 100%; } }

.Image-Gallery__thumbnail:first-child {
  margin-left: 0; }

.Image-Gallery__thumbnail:hover {
  cursor: pointer;
  opacity: 1.0;
  filter: alpha(opacity=1.0); }
