.ProductPicker {
  padding-left: 0;

  .ColorPalette {
    display: table;
    margin: 0 auto; } }

.ProductPicker__product {
  display: inline-block;
  max-width: 380px;
  list-style-type: none; }

.ProductPicker__product:hover {
  cursor: pointer; }

.ProductPicker__product-image img {
  width: 100%; }
