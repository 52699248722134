/*
 |--------------------------------------------------------------------------
 | Bootstrap
 |--------------------------------------------------------------------------
 */
$icon-font-path: "../../fonts/bootstrap/" /* overriding bootstrap default font path */;
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

/*
 |--------------------------------------------------------------------------
 | Plugins
 |--------------------------------------------------------------------------
 */
@import "../../../node_modules/sweetalert/dist/sweetalert";

$slick-font-path: "../../fonts/slick/";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";

@import "../../../node_modules/rangeslider.js/dist/rangeslider";

@import "../css/vendor/animatecss/animate";

/*
 |--------------------------------------------------------------------------
 | Base Layout
 |--------------------------------------------------------------------------
 */
@import "base/variables";
@import "base/typography";
@import "base/forms";
@import "base/icons";
@import "base/links";
@import "base/transitions";

/*
 |--------------------------------------------------------------------------
 | Components
 |--------------------------------------------------------------------------
 */
@import "components/Campaign";
@import "components/ImageGallery";
@import "components/Cart";
@import "components/ColorPalette";
@import "components/CountdownTimer";
@import "components/Order";
@import "components/OrderForm";
@import "components/OrderItems";
@import "components/SizeTable";
@import "components/ImageFileUpload";
@import "components/LoadingModal";
@import "components/WysiwygEditor";
@import "components/button";
@import "components/progressBar";
@import "components/slickCarousel";
@import "components/rangeSlider";
@import "components/nav";
@import "components/cookieBanner";
@import "components/mediumEditor";

@import "components/editor/Editor";
@import "components/editor/Steps";
@import "components/editor/ProductPicker";
@import "components/editor/Tools";
@import "components/editor/FontPicker";
@import "components/editor/Designer";
@import "components/editor/AdditionalProductsPicker";
