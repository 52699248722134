button::-moz-focus-inner {
  border: 0; }

button:focus {
  outline: none !important; }

.btn {
  border: 0;
  border-radius: 0;
  letter-spacing: 0.02em;
  background: white; }

.btn:focus, .btn.focus, .btn:active:focus, .btn.focus:active, .btn.active, .btn.active:focus, .btn.active.focus {
  outline: none;
  box-shadow: none; }

.btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
  color: white;
  font-weight: bold;
  background: $red; }

.btn-blank, .btn-blank:hover, .btn-blank:active, .btn-blank:focus {
  background: none; }
