@font-face {
  font-family: 'icomoon';
  src:    url('../../fonts/icons/icomoon.eot?opj4dw');
  src:    url('../../fonts/icons/icomoon.eot?opj4dw#iefix') format('embedded-opentype'),
  url('../../fonts/icons/icomoon.ttf?opj4dw') format('truetype'),
  url('../../fonts/icons/icomoon.woff?opj4dw') format('woff'),
  url('../../fonts/icons/icomoon.svg?opj4dw#icomoon') format('svg') {}
  font-weight: normal;
  font-style: normal; }


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }


.icon-add-circle .path1:before {
  content: "\e90c";
  color: rgb(0, 165, 202); }

.icon-add-circle .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255); }

.icon-t-shirt-back:before {
  content: "\e90e"; }

.icon-t-shirt-front:before {
  content: "\e90f"; }

.icon-printarea-off:before {
  content: "\e910"; }

.icon-printarea-on:before {
  content: "\e911"; }

.icon-t-shirt-full:before {
  content: "\e912"; }

.icon-rocket:before {
  content: "\e6a9"; }

.icon-checkmark:before {
  content: "\e714"; }

.icon-checkmark-outline:before {
  content: "\e715"; }

.icon-facebook:before {
  content: "\e790"; }

.icon-twitter:before {
  content: "\e795"; }

.icon-arrow-right-bold:before {
  content: "\e907"; }

.icon-arrow-right:before {
  content: "\e908"; }

.icon-add:before {
  content: "\e900"; }

.icon-reset:before {
  content: "\e901"; }

.icon-layer-down:before {
  content: "\e903"; }

.icon-rotate:before {
  content: "\e904"; }

.icon-center-horizontal:before {
  content: "\e905"; }

.icon-layer-up:before {
  content: "\e909"; }

.icon-center-vertical:before {
  content: "\e90a"; }

.icon-delete:before {
  content: "\e90b"; }

.icon-design-elements-2:before {
  content: "\e902"; }

.icon-upload-images:before {
  content: "\e906"; }

.icon-text:before {
  content: "\e601"; }

.icon-t-shirt:before {
  content: "\e816"; }

.icon-cc-visa:before {
  content: "\f1f0"; }

.icon-cc-mastercard:before {
  content: "\f1f1"; }

.icon-cc-discover:before {
  content: "\f1f2"; }

.icon-cc-amex:before {
  content: "\f1f3"; }

.icon-cc-paypal:before {
  content: "\f1f4"; }

.icon-cc-stripe:before {
  content: "\f1f5"; }
