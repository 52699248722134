$designer-width: 500px;
$designer-height: 500px;
$canvas-width: $designer-width;
$canvas-height: $designer-height;

.Designer {
  position: relative;
  width: $designer-width;
  z-index: $layer-bottom; }

#canvas-front-wrapper, #canvas-back-wrapper {
  position: relative;
  width: $canvas-width;
  height: $canvas-height;
  top: 0;
  left: 0; }

#canvas-front-background, #canvas-back-background {
  position: absolute;
  top: 0;
  left: 0;
  width: $canvas-width;
  height: $canvas-height;
  background-repeat: no-repeat;
  z-index: $layer-bottom; }

#canvas-front-template, #canvas-back-template {
  position: absolute;
  top: 0;
  left: 0;
  width: $canvas-width;
  height: $canvas-height;
  visibility: hidden;
  z-index: $layer-bottom; }

.Designer__front-back-switcher {
  display: table;
  margin: 0 auto;

  .btn {
    color: $light-grey;
    font-weight: bold; }

  .btn:hover, .btn--active {
    color: $black; } }

.Designer__object-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  z-index: $layer-top; }

.Designer__object-controls__inner {
  display: table;
  margin: 0 auto;

  .btn {
    padding: 2px 4px;
    color: $grey;
    font-size: 1.2em;
    text-align: center;
    background: $very-light-grey; }

  .btn:hover {
    color: $black; } }
