.ImageFileUpload__images-list {
  padding-left: 0;
  list-style-type: none; }

.ImageFileUpload__remove-image-button {
  position: absolute;
  top: 5px;
  right: 20px;
  padding: 2px 4px;

  .icon {
    font-size: 2em;
    line-height: 1.2em; } }

.ImageFileUpload__file-upload-button-wrapper {
  position: relative;
  margin: 10px 0;
  overflow: hidden;

  input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0); } }
