.slick-prev, .slick-next {
  width: 40px;
  height: 40px; }

.slick-prev {
  left: -40px; }

.slick-next {
  right: -40px; }

.slick-prev::before, .slick-next::before {
  font-family: "icomoon";
  color: $light-grey;
  font-size: 40px;
  content: "\e908"; }

.slick-prev {
  -webkit-transform: rotate(180deg) translate(0px, 50%);
  -moz-transform: rotate(180deg) translate(0px, 50%);
  -ms-transform: rotate(180deg) translate(0px, 50%);
  -o-transform: rotate(180deg) translate(0px, 50%);
  transform: rotate(180deg) translate(0px, 50%); }
